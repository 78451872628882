import React, { lazy } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
// import { TransitionGroup, CSSTransition } from "react-transition-group";

const LandingContainer = lazy(() => import("../containers/LandingContainer"));
const AboutContainer = lazy(() => import("../containers/AboutContainer"));
const ProductsContainer = lazy(() => import("../containers/ProductsContainer"));
const ProductsSingleItemContainer = lazy(() =>
  import("../containers/ProductsSingleItemContainer")
);
const ContactContainer = lazy(() => import("../containers/ContactContainer"));
const InfrastructureContainer = lazy(() =>
  import("../containers/InfrastructureContainer")
);
const QualityContainer = lazy(() => import("../containers/QualityContainer"));
const TeamContainer = lazy(() => import("../containers/TeamContainer"));
const NotFoundContainer = lazy(() => import("../containers/NotFoundContainer"));

const AppRouter = () => {
  let location = useLocation();

  return (
    // <TransitionGroup>
    //   <CSSTransition key={location.key} classNames="my-node" timeout={3000}>
    <Switch location={location}>
      <Route exact path="/" component={LandingContainer} />
      <Route exact path="/about" component={AboutContainer} />
      <Route exact path="/products" component={ProductsContainer} />
      <Route
        exact
        path="/products/:id"
        component={ProductsSingleItemContainer}
      />
      <Route exact path="/infrastructure" component={InfrastructureContainer} />
      <Route exact path="/team" component={TeamContainer} />
      <Route exact path="/contact" component={ContactContainer} />
      <Route exact path="/quality" component={QualityContainer} />
      <Route path="/404" exact component={NotFoundContainer} />
      <Redirect to="/404" />
    </Switch>
    //   </CSSTransition>
    // </TransitionGroup>
  );
};

export default AppRouter;
