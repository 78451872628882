import React from "react";
import style from "./preloader.module.scss";

function Preloader() {
  return (
    <div className={style.loader}>
      <span>Loading...</span>
    </div>
  );
}

export default Preloader;
